/*
Material Icon and Symbol Library
 */
@import url('material-icons/iconfont/material-icons.css');
@import url('material-symbols');

/*
Bootstrap 4
 */

@import url("css/bootstrap.min.css");

/*
Bootstrap 5
 */

@import url("css/bootstrap5.min.css.scss");

/*
Base css with all general settings
 */
@import url("css/base.css");

/*
Sidebar css with all style to sidebar
 */
@import url("css/sidebar.css");

/*
Footer css with all style to footer
 */
@import url("css/footer.css");

/*
Modal css with all style to modal
 */
@import url("css/modal.css");

/*
React Page CSS with all style to ract-page
 */
@import url("css/react-page.css");

/*
Scrollbar CSS with all style to scrollbar
 */
@import url("css/scrollbar.css");

/*
Tooltip CSS with all style to tooltip
 */
@import url("css/tooltip.css");

/*
Modul Masonry CSS with all style to masonry
 */
@import url("css/module/masonry.css");

/*
Modul Hexagon CSS with all style to hexagon
 */
@import url("css/module/hexagon.css");

/*
Unsortiertes CSS Muss noch zugeordnet werden
 */
@import url("css/unsort.css");

/* Ampelsystem */
#TablePaymentHistory{
    --bs-table-bg:transparent  !important;
}

.PaymentHistroyRed {
    background-color: rgba(178, 34, 34, 0.8) !important;
}

.PaymentHistroyRed td{
    color:white  !important;
    font-weight: bold;
}

.PaymentHistroyGreen{
    background-color: rgba(0, 128, 0, 0.8) !important;
}

.PaymentHistroyGreen td{
    color:white  !important;
    font-weight: bold;
}

.PaymentHistroyYellow{
    background-color: rgba(255, 255, 153, 0.8) !important;
}

.PaymentHistroyYellow td{
    color:black  !important;
    font-weight: bold;
}

/* Ende Ampelsystem */


.adjustWidth{
    max-width: 20px;
}


.css-dip3t8{
    overflow-y: hidden !important;
}


#login{
    margin-bottom:25px
}


.theme-footer{
    display:none
}

.ContentWrapper{
    min-height: 94%;
    margin-bottom: 0px !important;
    margin-top: 65px !important;
}

.SidebarLogo{
    width: 235px;
    object-fit: contain;
}


.theme-header{
    box-shadow: unset;
    border-bottom: 2px solid #d5d5d5;
}
.SidebarContent{
    background: white !important;
    overflow-y: auto  !important;
}

.first{
    color:black  !important;
}

.box{
    border:0px;
}

#wrapperContent{
    border-left: 2px solid #d5d5d5;
}

.Login{
 background: unset;
 background:unset;
 border: 2px solid #d5d5d5;
 border-radius: 15px;
}

.LoginField {
    margin-bottom: 15px;
    background: #666666 !important;
    border-color: #333333 !important;
}

.LoginField:hover {
    background: #d5d5d5 !important;
    color:#333333 !important;
}

.MenuItem {
    padding-left:25px
}

.css-um1o6k{
    display: inline-block;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    border-right: 2px solid currentcolor;
    border-bottom: 2px solid currentcolor;
    width: 5px;
    height: 5px;
    -webkit-transform: rotate(-45deg)  !important;
    -moz-transform: rotate(-45deg)  !important;
    -ms-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;

}

.css-1097eso{
    display: none !important;
}

body{
    background:white;
}
.css-1l5fxv3 {
    display: none;
    overflow: hidden;
    z-index: 999;
    -webkit-transition: height 300ms;
    transition: height 300ms;
    box-sizing: border-box;
    background-color: white;
    background-color: white;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    padding-left: 0px;
    width: 200px;
    border-radius: 4px;
    height: auto !important;
    display: block !important;
    -webkit-transition: none !important;
    transition: none !important;
    visibility: visible;
}
