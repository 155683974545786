/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/



.text-small {
    font-size: 0.9rem;
}

.group-box{
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 15px;
    background-color: white;
}

.messages-box{
    height: 510px;
    overflow-y: scroll;
    background-color: white;
}

.chat-box{
    overflow-y: scroll;
    max-height: 94%;
    background-color: #f4f7fe !important;
}

#messages-boxlist {
    height: 95%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.chatHolder {
    position: relative;
    height: 100%;
}

.bgChat{
    background-color: #f4f7fe !important;
}

.rounded-lg {
    border-radius: 0.5rem;
}

input::placeholder {
    font-size: 0.9rem;
    color: #999;
}

#chatForm{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 6%;
}

.emoji {
    height: 450px;
    width: 350px;
    position: absolute;
    bottom: 48px;
    display:none;
}


.sendButton {
    background-color: white !important;
    border: 1px solid #015b91!important;
}


.textMessager{
    max-height: 50px;

}
