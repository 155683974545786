.w300{
    width:300px
}

.mcenter{
    margin: 0 auto;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
