.boxDragGrid {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: white;
    width: 320px;
    height: max-content;
}

.draggable-header-Grid {
    display: flex;
    align-items: center;
    text-align: right;
    cursor: grab;
    padding: 10px;
    border-radius: 4px;
    background-color: #f7f7f7;
}

.draggable-header-Grid:active {
    cursor: grabbing;
}

