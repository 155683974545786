.timeline {
    border-left: 1px solid #23AAE1;
    position: relative;
    list-style: none;
}

.timeline .timeline-item {
    position: relative;
}

.timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .timeline-item:after {
    background-color: #23AAE1;
    left: -38px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
}

.LabelMulti {
    padding-left: 25px;
    padding-top: 10px;
    font-weight: bolder;
}

.TableTicket {
    background: rgb(250, 250, 250);
    padding: 5px 10px;
    border-inline-start: 1px solid rgba(0, 0, 0, 0.176);
    margin-left: -19px !important;
    margin-right: -30px !important;
}

.spinnerSS {
    margin-top: -10px;
    margin-left: 1px;
    margin-right: 1px;
    height: 150px;
}

.timeoutSpinner {
    animation-delay: 2s;
    animation-duration: 1ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: spinnerDisplay;
}

@keyframes spinnerDisplay {
    to {
        display: none;
    }
}

.parent {
    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.div1 {
    grid-area: 1 / 1 / 2 / 2;
}

.div2 {
    grid-area: 2 / 1 / 3 / 2;
}

.div3 {
    grid-area: 1 / 2 / 3 / 3;
}


.Checkbolder {
    background: #dee2e6;

}

.form-check-inline label {
    margin-top: -3px;
    display: block;
}

.tableHeight {
    height: 22px;
    overflow: hidden;
}


#Communication {
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.google-visualization-legend {
    position: absolute;
    bottom: 0;
}

.dl.horizontal {
    font-size: 12px;
    width: 500px;
}

.dl.horizontal .dt {
    float: left;
    width: 150px;
    clear: both;
    margin: 0 0 5px 0;
    padding: 3px;
}

.dl.horizontal .dd {
    float: left;
    width: 300px;
    border: 1px solid #aaaaaa;
    margin: 0 0 5px 0;
    padding: 2px;
    -moz-box-shadow: 1px 1px 3px #aaaaaa;
}

.dl.horizontal .dd span {
    background: #1770d3;
    display: block;
    color: #ffffff;
    text-indent: 4px;
}


.vertical {
    font-size: 12px;
    height:400px;

}

.vertical .dt {
    float: left;
    width: 70px;
    margin: 355px 0 0  0;
    text-align: center;
}

.vertical .dd {
    float: left;
    width: 60px;
    margin-right:10px;

    /*height: 350px;
    margin: 0px 15px 0 0px;
    padding: 0 2px 0 2px;
    position: relative;
    -moz-box-shadow: 1px 1px 3px #aaaaaa;*/
}

.vertical .dd .span {
    width: 60px;
    display: block;
    position: absolute;
    bottom: 0;
    background: #d3179c;
    color: #ffffff;
    text-align: center
}
